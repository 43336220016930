<template>
	<div>
		<Sidebar v-model:visible="show" class="p-sidebar p-sidebar-sm">
			<div class="flex align-items-center justify-content-center mb-6">
				<img src="../../assets/Logo-login.webp" alt="Logo do Quinteto Sal da Terra" width="180" height="180" />
			</div>
			<div>
				<PanelMenu :model="menu" />
			</div>
		</Sidebar>
	</div>
</template>

<script>
import { ref } from '@vue/reactivity';
import PanelMenu from 'primevue/panelmenu';
import Sidebar from 'primevue/sidebar';
import { useRouter } from 'vue-router';

export default {
	components: { Sidebar, PanelMenu },
	setup() {
		const show = ref(false);
		const router = useRouter();
		const navigate = (route) => {
			router.push(route);
			show.value = !show.value;
		};
		const menu = ref([
			{
				label: 'Início', icon: 'pi pi-fw pi-home', command: () => { navigate('/') }
			},
			{
				label: 'Cadastro',
				items:
					[
						{ label: 'Audições', icon: 'pi pi-fw pi-globe', command: () => { navigate('/audicoes') } },
						{ label: 'Cerimonialistas', icon: 'pi pi-fw pi-users', command: () => { navigate('/cerimonialistas') } },
						{ label: 'Cidades', icon: 'pi pi-fw pi-building', command: () => { navigate('/cidades') } },
						{ label: 'Locais', icon: 'pi pi-fw pi-map-marker', command: () => { navigate('/locais') } },
						{ label: 'Momentos', icon: 'pi pi-fw pi-star', command: () => { navigate('/momentos') } },
						{ label: 'Músicas', icon: 'pi pi-fw pi-play', command: () => { navigate('/musicas') } },
						{ label: 'Solistas', icon: 'pi pi-fw pi-star', command: () => { navigate('/solistas') } }
					]
			},
			{
				label: 'Cartilha', icon: 'pi pi-fw pi-book', command: () => { navigate('/cartilha') }
			},
			{
				label: 'Cerimônias', icon: 'pi pi-fw pi-heart', command: () => { navigate('/cerimonia') }
			},
			{
				label: 'Relatórios',
				items:
					[
						{ label: 'Como Conheceram o QST', icon: 'pi pi-fw pi-search', command: () => { navigate('/relatorio-como-conheceram-o-qst') } },
						{ label: 'Contador de Cerimônias', icon: 'pi pi-fw pi-calendar-plus', command: () => { navigate('/contador-cerimonias') } },
						{ label: 'Cerimonial', icon: 'pi pi-fw pi-users', command: () => { navigate('/relatorio-cerimoniais') } },
						{ label: 'Cerimônias com Pendências', icon: 'pi pi-fw pi-exclamation-triangle', command: () => { navigate('/cerimonias-pendentes') } },
						{ label: 'Dicas de Músicas', icon: 'pi pi-fw pi-volume-up', command: () => { navigate('/dicas-de-musicas') } },
						{ label: 'Fechamento de Contratos', icon: 'pi pi-fw pi-briefcase', command: () => { navigate('/fechamento-contratos') } },
						{ label: 'Musicas', icon: 'pi pi-fw pi-play', command: () => { navigate('/relatorios-musica') } },
						{ label: 'Conversão de Orçamentos', icon: 'pi pi-chart-line', command: () => { navigate('/relatorio-orcamentos') } },
					]
			},
			{
				label: 'Financeiro',
				items:
					[
						{ label: 'Cálculo da Km', icon: 'pi pi-fw pi-car', command: () => { navigate('/calculo-km') } },
						{ label: 'Contratos', icon: 'pi pi-fw pi-briefcase', command: () => { navigate('/contratos') } },
						{ label: 'Orçamentos', icon: 'pi pi-fw pi-calculator', command: () => { navigate('/orcamentos') } },
						{ label: 'Recibos', icon: 'pi pi-fw pi-ticket', command: () => { navigate('/recibos') } },
						{ label: 'Tabela de Preços', icon: 'pi pi-fw pi-dollar', command: () => { navigate('/tabela-precos') } },
					]
			},
			{
				label: 'Meu Perfil', icon: 'pi pi-fw pi-user', command: () => { navigate('/perfil-integrante') }
			},
			{
				label: 'Sair', icon: 'pi pi-fw pi-power-off', command: () => { navigate('/logout') }
			}]);

		return { show, menu, router };
	},
	methods: {
		toggleSidebar() {
			this.show = !this.show;
		}
	}
}
</script>

<style scoped>
:deep(.p-panelmenu) .p-panelmenu-header>a {
	padding: 1rem;
	border-bottom: 1px solid #ffe69c;
	border-top: none;
	border-left: none;
	border-right: none;
	color: #D0B45E;
	background: #3e3d3d;
	font-weight: 600;
	border-radius: 3px;
	transition: box-shadow 0.2s;
	margin-bottom: 10px;
	font-size: 1.2rem;
}

:deep(.p-panelmenu) .p-panelmenu-content {
	border: none;
	background: #3e3d3d;
}

:deep(.p-panelmenu) .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
	color: #D0B45E;
	font-weight: 600;
}

:deep(.p-panelmenu) .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
	color: #D0B45E;
}

:deep(.p-panelmenu) .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
	background: #746f60;
	transition: 0.5s;
	color: #D0B45E;
}

:deep(.p-panelmenu) .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover {
	background: #746f60;
	color: #D0B45E;
}

:deep(.p-panelmenu) .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
	background: #746f60;
	transition: 0.5s;
}

:deep(.p-panelmenu) .p-panelmenu-header.p-highlight>a {
	background: #3e3d3d;
	color: #D0B45E;
}

:deep(.p-panelmenu) .p-panelmenu-header .p-panelmenu-header-content {
	border-bottom: 1px solid #ffe69c;
	border-top: none;
	border-left: none;
	border-right: none;
	color: #D0B45E;
	background: #3e3d3d;
	font-weight: 600;
	border-radius: 3px;
	transition: box-shadow 0.2s;
	margin-bottom: 10px;
	font-size: 1.2rem;
}

:deep(.p-panelmenu) .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
	background: #3e3d3d;
	color: #D0B45E;
}

:deep(.p-panelmenu) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
	background: #746f60;
	color: #D0B45E;
	transition: 0.5s;
}

:deep(.p-panelmenu) .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
	background: #746f60;
	color: #D0B45E;
	transition: 0.5s;
}

:deep(.p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover)).p-highlight .p-button .p-button-icon-left, .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover).p-highlight .p-button .p-button-icon-right {
    color: #218302;
}

:deep(.p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover)):not(.p-highlight) .p-button .p-button-icon-left, .p-togglebutton:not(.p-disabled):has(.p-togglebutton-input:hover):not(.p-highlight) .p-button .p-button-icon-right {
    color: #ad0223;
}

:deep(.p-togglebutton) .p-button .p-button-icon-left, .p-togglebutton .p-button .p-button-icon-right {
    color: #ad0223;
}

:deep(.p-togglebutton.p-highlight) .p-button .p-button-icon-left, .p-togglebutton.p-highlight .p-button .p-button-icon-right {
    color: #218302;
}

:deep(.p-panelmenu) .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
	color: #d0b45e;
	padding: 1rem;
	font-weight: 600;
}

span {
	color: #D0B45E;
}
</style>